import * as React from "react";
import Layout from "../../components/layouts/Layout";
import backgroundHex from "../../assets/img/backgroundSections/background.webp";
import FarmHouseComponent from "../../components/FarmHouse";

const farmHouse = () => {
    return(
        <Layout background={backgroundHex}>
            <FarmHouseComponent />
        </Layout>
    )
}
export default farmHouse;