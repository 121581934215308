import React, { useState } from 'react';
import { Link } from "gatsby";
import Card from './card';

const FarmHouse = () => {
    return(
        <div className="p-5">
            <div className="flex justify-center lg:justify-start">
                <div className="rounded-xl p-4 text-center text-white bg-primaryDark">
                    <div className="font-semibold text-lg">CREATE NEW FARM HOUSE</div>
                    <div className="mt-1">
                        <Link to="/farmHouse/create">
                            <button className="font-semibold text-lg py-1 px-3 bg-amber-500 rounded-md">
                                CREATE
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-12 mt-9">
                <Card/>
            </div>
        </div>
    )
}

export default FarmHouse;