import React, { useState } from 'react';
import { Link } from "gatsby";

import icon_pencil from '../../assets/vector/icons/pencil.svg';
import farm_cover from '../../assets/img/farmHouse/farm_cover.webp';
import Icons from '../../assets/vector/icons';

const Card = () => {
    const [hoptions, change_hoptions] = useState(false);

    return(
        <div className="relative rounded-b-lg overflow-hidden" onMouseEnter={() => {change_hoptions(!hoptions)}} onMouseLeave={() => {change_hoptions(!hoptions)}}>
            <div className="p-2 bg-primaryDark text-white">
                <div className="flex items-center">
                    <span className="font-semibold text-lg mr-3">Farm House 1</span>
                    <img src={icon_pencil} alt="pencil-icon" />
                </div>
                <div className="py-2 px-8">
                    <img src={farm_cover} alt="farm-icon" className="w-full"/>
                </div>
            </div>
            <div className="bg-white p-5 text-center font-bold text-2xl">
                <span>230 <span className="text-primaryDark">WP</span></span>
                <span className="mx-5">|</span>
                <span>7 <span className="text-darkTeal">Tractors</span></span>
            </div>
            <div className={`absolute justify-center items-center top-0 bottom-0 left-0 right-0 bg-transparentDark ${hoptions?'flex':'hidden'}`}>
                <Link to="/farmHouse/view">
                    <div className="m-3 font-semibold text-lg rounded-lg bg-primarypeach hover:bg-primaryDark text-primaryDark hover:text-white w-40 h-28 flex justify-center items-center flex-col cursor-pointer">
                        <div className="text-center">View Details</div>
                        <div className="">
                            <Icons.eye/>
                        </div>
                    </div>
                </Link>
                <div className="m-3 font-semibold text-lg rounded-lg bg-primarypeach hover:bg-primaryDark text-primaryDark hover:text-white w-40 h-28 flex justify-center items-center flex-col cursor-pointer">
                    <div className="text-center">Dismatle your House</div>
                    <div className="">
                        <Icons.heart/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Card;